import React, { Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'

import './App.css';
import './Apps/dices/src/index.css';
import './Apps/alarms/src/index.css';
import './Apps/timer/src/index.css';

// import Dices from './Apps/dices/src/App'
// import Alarms from './Apps/alarms/src/App'
// import ConfigurationProvider from './Apps/timer/src/store/store-context'
// import Timer from './Apps/timer/src/App'

import Layout from './components/layout/Layout';

const ShopMeals = React.lazy(() => import('./Apps/shop-meals/src/AppContainer'))
const Dices = React.lazy(() => import('./Apps/dices/src/App'))
const Alarms = React.lazy(() => import('./Apps/alarms/src/App'))
const ConfigurationProvider = React.lazy(() => import('./Apps/timer/src/store/store-context'))
const Timer = React.lazy(() => import('./Apps/timer/src/App'))
const Calculator = React.lazy(() => import('./Apps/calculator-simple/src/App'))
const DrumMachine = React.lazy(() => import('./Apps/drum-machine/src/App'))

export const ShopMealsBase = "/shop-meals"

function App() {
  return (
    <Layout>
      <Suspense fallback={<p>A carregar...</p>}>
        <Routes>
          <Route path='/' element={<Dices />} />
          <Route path={ShopMealsBase + '/*'} element={<ShopMeals />} />
          <Route path='/dices' element={<Dices />} />
          <Route path='/timer' element={<>
            <ConfigurationProvider>
              <Timer />
            </ConfigurationProvider>
          </>} />
          <Route path='/alarms' element={<Alarms />} />
          <Route path='/calculator' element={<Calculator />} />
          <Route path='/drum-machine' element={<DrumMachine />} />
        </Routes>
      </Suspense>
    </Layout>
  );
}

export default App;
