import { Link } from "react-router-dom"

export default function MainNavigation() {

    return <header className="main-header">
        <div className="logo">React Apps</div>
        <nav>
            <Link to='/'>Início</Link>
            <Link to='/shop-meals'>Shop</Link>
            <Link to='/dices'>Dados</Link>
            <Link to='/calculator'>Calculadora</Link>
            <Link to='/drum-machine'>DrumMachine</Link>
            <Link to='/alarms'>Alarmes</Link>
            <Link to='/timer'>Temporizador</Link>
        </nav>
    </header>
}